
import { computed, defineComponent, onBeforeMount, onMounted, Prop, PropType, ref, watch } from 'vue'
import { getTranslationsEntries } from '@/api/translations.api'
import { TranslationEntry } from '@/models/translations/TranslationEntry.model'
import { SetMail } from '@/models/email-sending/SetMail'
import { EmailSendingAPI } from '@/api/email-sending.api'
import { ActivityRecordAPI } from '@/api/activity-record.api'
import { EmailData } from '@/models/email-sending/EmailData'
import { DataRecord } from '@/models/activity-record/ActivityRecord'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { CoderEmails } from '@/utils/enums'
import { useToast } from 'primevue/usetoast'
import { StaffTracing } from '@/models/tracing-staff/classes/StaffTracing'
import { ProfileEducation } from '@/models/education-students/classes/education-student-profile'

export default defineComponent({
  props: {
    visible: {
      type: Boolean
    },
    StaffTracing: {
      type: Array as PropType<StaffTracing[]>,
      required: true
    }
  },
  emits: ['onClose'],
  setup (props, { emit }) {
    const titles = Array<any>()
    const visibleProp = computed(() => props.visible)
    // const isVisible = ref<boolean>(visibleProp.value)
    const subject = ref<string>()
    const isLoading = ref(false)
    const selected = ref(true)
    const body = ref<string>()
    const SelectedTags = ref<[]>([])
    const actions = ref([
      { option: 'Call', code: 'CALL' },
      { option: 'Material', code: 'MAT' },
      { option: 'Feedback por mail', code: 'FEEDBACK' },
      { option: 'Mail minuta', code: 'MAIL' },
      { option: 'No se acciona', code: 'NA' }
    ])
    const state = ref([
      { option: 'Primera Vez', code: 'FT' },
      { option: 'Mejoró', code: 'IMPR' },
      { option: 'Se repite', code: 'REP' },
      { option: 'B2C', code: 'B2C' },
      { option: 'No corresponde', code: 'NA' }
    ])
    const statesSelected = ref([])
    const actionsSelected = ref([])

    onBeforeMount(() => {
      isLoading.value = true
    })

    const endMailSending = () => {
      emit('onClose', false)
    }

    // watch(visibleProp, () => { isVisible.value = visibleProp.value })

    return {
      // isVisible,
      visibleProp,
      subject,
      actions,
      actionsSelected,
      state,
      statesSelected,
      endMailSending,
      isLoading,
      value: true,
      selected,
      SelectedTags,
      body
    }
  }
})

